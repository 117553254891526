/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import PropagateLoader from "react-spinners/PropagateLoader";
import './App.css';
import checkMark from './static/images/success-green-check-mark.png';
import errorMark from './static/images/error.png';
require('dotenv').config();

const ErrorComp = () => {
  return (
    <div className='fullscreenDiv'>
      <div className='centerRes' >
        <p>
          <img src={errorMark} alt="errorMark" className='checkMark' />
        </p>
        <p className='centerResP'>Sorry</p>
        <p className='centerResP2'>we can't find your registration.</p>
      </div>
    </div>
  );
};

export default function App() {

  const parsed = queryString.parse(location.search);
  const eventId = parsed.eventId;
  const findId = parsed.findId;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState(false);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL+"/registrant/verify/?eventId=" + eventId + "&findId=" + findId + "&token="+process.env.REACT_APP_API_TOKEN)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [eventId, findId]);

  if (error) {
    return <ErrorComp />;
  } else if (!isLoaded) {
    return (
      <div className='fullscreenDiv'>
        <div className='center'>
          <PropagateLoader size={15} height={35} width={4} radius={2} margin={2} color='#36D7B7' loading={true} />
        </div>
      </div>
    );
  } else {
    if (items) {
      console.log("items.registrant", items.registrant);
      if (items.registrant) {
        if (items.registrant[0]) {
          if (items.registrant[0]._id) {
            return (
              <div className='fullscreenDiv'>
                <div className='centerRes' >
                  <p>
                    <img src={checkMark} alt="checkmark" className='checkMark' />
                  </p>
                  <p className='centerResP'>Thank you</p>
                  <p className='centerResP2'>your Email has been verified.</p>
                  <p className='centerResP3'>You can access the event by <a href={`${process.env.REACT_APP_WEBCASTING_PLATFORM_URL}/broadcast/${eventId}/${findId}`}>clicking here</a></p>
                </div>
              </div>
            );
          } else {
            return <ErrorComp />;
          }
        } else {
          return <ErrorComp />;
        }
      } else {
        return <ErrorComp />;
      }
    } else {
      return <ErrorComp />;
    }
  }
}
